#thankYouFirstSection {
    display: block;
    background: rgba(236,245,248,1);
    border-style: solid none none none;
    border-width: 1px;
    border-color: #45BF7C;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
    border-radius: 0px;
    width: 100%;
    position: relative;
    padding: 5em 0;
}
#thankYouFirstSection .col-lg-12.text-center {
    margin-left: auto;
    margin-right: auto;
    width: 1180px;
}
#thankYouFirstSection .col-lg-12.text-center span{
    font-weight: 700;
    font-style: normal;
    color: rgb(7, 95, 138);
    font-size: 40px;
    line-height: 1.3;
}

.thank-you-lenders__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: var(--white);
    height: 100vh;
  }
  
  .thank-you-lenders__title {
    font-size: 1em;
    font-weight: 700;
    color: var(--dove-gray);
    text-align: center;
    margin-top: 0.8em;
  }
  
  .thank-you-lenders__list {
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: auto;
    height: 100%;
  }
  
  .thank-you-lenders__card-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .thank-you-lenders__card {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    background-color: var(--white);
    cursor: pointer;
    transition-property: box-shadow, transform;
    box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
  }
  
  .thank-you-lenders__img {
    text-align: center;
    margin-top: 2em;
  }
  
  .thank-you-lenders__img img {
    margin-bottom: 10px;
    width: 220px;
  }
  
  .thank-you-lenders__description {
    margin: 0.5em 3.5em 0.5em 3.5em;
    text-align: center;
  }
  
  .thank-you-lenders__description-header {
    width: 100%;
    line-height: 2.2em;
  }
  
  .thank-you-lenders__description-header span {
    display: inline-block;
    font-size: 1.3em;
    text-transform: capitalize;
    font-weight: 600;
    color: rgb(7, 95, 138);
  }
  
  .thank-you-lenders__description-bulletpoints {
    text-align: justify;
    font-family: inherit;
    font-size: 14px;
  }
  
  .thank-you-lenders__quote-container {
    font-size: 1em;
    line-height: 1.5;
    border-radius: 0.25rem;
    margin: auto;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .thankyou__lenders-quote-btn {
    padding: 13px 32px;
    background-color: rgba(69,191,124,1);
    font-style: normal;
	font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: var(--white);
    text-decoration: none;
    display: table;
    text-align: center;
    font-weight: 600;
    z-index: 2;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    margin-bottom: 2em;
    transition: background-color 0.3s ease;
  }

  .thankyou__lenders-quote-btn:hover {
    padding: 13px 32px;
    background-color: rgba(69,191,124,1);
    font-style: normal;
	font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: var(--white);
    text-decoration: none;
    display: table;
    text-align: center;
    font-weight: 600;
    z-index: 2;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    margin-bottom: 2em;
    background-color: rgba(52,181,105,1);
  }
  
  .thank-you-lenders__phone {
    text-align: center;
    font-size: 1.2rem;
    color: rgb(7, 95, 138);
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
    line-height: 1.8em;
  }
  
  .thank-you-lenders__pixel {
    display: none;
  }
  
  @media screen and (min-width: 768px) {
    .thank-you-lenders__container {
      gap: 4rem;
    }
  
    .thank-you-lenders__card-wrapper {
      gap: 2.5rem;
    }
  
    .thank-you-lenders__title {
      font-size: 1.7rem;
    }
  
    .thank-you-lenders__card {
      flex-direction: row;
      padding: 2em;
    }
  
    .thank-you-lenders__phone {
      font-size: 1.7rem;
    }
  
    .thank-you-lenders__img img {
      width: 200px;
    }
  
    .thank-you-lenders__description-header span {
      text-align: left;
    }
  
    .thank-you-lenders__description-bulletpoints {
      font-size: 18px;
    }
  }
  

@media(max-width: 991px){

    #thankYouFirstSection .col-lg-12.text-center span{
        font-size: 35px;
    }

}

@media(max-width: 767px){
    #thankYouFirstSection .col-lg-12.text-center span{
        font-size: 32px;
    }

    .thank-you-lenders__phone {
        margin-top: 1em;
    }

    #thankYouFirstSection {
        padding: 1.5em 0;
    }

    .thank-you-lenders__description-header span {
        line-height: 1.1em;
    }
}

@media(max-width: 568px){
    #thankYouFirstSection .col-lg-12.text-center span{
        font-size: 1.1em;
    }

    #lender-list {
        padding-top: 0.5em !important; /* Reduce padding for mobile */
        padding-bottom: 0.5em !important;
    }

    .thank-you-lenders__img {
        margin: 0.5em;
    }
    
    .thankyou__lenders-quote-btn {
        margin-bottom: 1em;
    }

}

